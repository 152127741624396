
import { computed, defineComponent, reactive } from 'vue';
import { IOptionString } from '@/core/models/AntDesign/IOption';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { useTelaBase } from '@/core/composables/TelaBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ServicoUtilitario from '@/servicos/MeuSistema/ServicoUtilitario';

export default defineComponent({
  name: 'SelecionarCest',
  props: {
    varios: {
      type: Boolean,
      default: false,
    },
    codigoSelecionado: {
      type: String || undefined,
    },
    codigosSelecionados: {
      type: Array as () => string[],
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Digite para selecionar',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:codigoSelecionado', 'update:codigosSelecionados', 'change'],
  setup(props, { emit }) {
    const { montaOpcoesComListaItemGenericoTexto } = useTelaBase();

    let debounce = 0;
    const servicoUtilitario = new ServicoUtilitario();

    const state = reactive({
      listaCESTs: [] as IOptionString[],
      buscandoDados: false,
      emDigitacao: false,
    });

    async function obterCESTPorCodigo(valor: any) {
      if (state.emDigitacao) { return; }
      if (!UtilitarioGeral.valorValido(valor)) { return; }

      if (props.varios) {
        if (props.codigosSelecionados.length === 0) { return; }
      } else if (props.codigoSelecionado === '') {
        return;
      }
      let efetuarConsulta = true;

      if (UtilitarioGeral.validaLista(state.listaCESTs)) {
        if (props.varios) {
          const listaCodigosConsulta = state.listaCESTs.map((c) => c.value);
          const diferencas = props.codigosSelecionados.filter((c) => !listaCodigosConsulta.includes(c));
          if (!UtilitarioGeral.validaLista(diferencas)) {
            efetuarConsulta = false;
          }
        } else {
          const existeLista = state.listaCESTs.find((c) => c.value === props.codigoSelecionado);
          if (existeLista !== undefined) {
            efetuarConsulta = false;
          }
        }
      }

      if (!efetuarConsulta) return;

      state.listaCESTs = [];

      const parametrosConsultaRapida: IParametrosConsultaRapida = {
        apenasAtivos: false, recursoAssociado: '',
      };

      if (props.varios) {
        parametrosConsultaRapida.filtrarPorVariosCodigos = true;
        parametrosConsultaRapida.valores = valor;
      } else {
        parametrosConsultaRapida.filtrarPorCodigo = true;
        parametrosConsultaRapida.valor = valor;
      }

      const listaCESTs = await servicoUtilitario.consultaCEST(valor);
      state.listaCESTs = montaOpcoesComListaItemGenericoTexto(listaCESTs);
      if (!UtilitarioGeral.validaLista(listaCESTs)) {
        if (props.varios) {
          emit('update:codigosSelecionados', undefined);
        } else {
          emit('update:codigoSelecionado', undefined);
        }
      }
    }

    async function pesquisarCEST(valorPesquisa: any) {
      state.emDigitacao = true;

      state.buscandoDados = true;
      state.listaCESTs = [];
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        const listaCESTs = await servicoUtilitario.consultaCEST(valorPesquisa);
        state.buscandoDados = false;
        state.listaCESTs = montaOpcoesComListaItemGenericoTexto(listaCESTs);
      }, 600);
    }

    function obterCEST():any {
      obterCESTPorCodigo(props.codigoSelecionado);
      return props.codigoSelecionado;
    }

    const computedCodigoSelecionado = computed({
      get: () => obterCEST(),
      set: (val: number) => {
        emit('update:codigoSelecionado', val);
      },
    });

    function obterCESTs():string[] {
      obterCESTPorCodigo(props.codigosSelecionados);
      return props.codigosSelecionados;
    }

    const computedCodigosSelecionados = computed({
      get: () => obterCESTs(),
      set: (val: string[]) => {
        emit('update:codigosSelecionados', val);
      },
    });

    function change() {
      emit('change', props.varios ? props.codigosSelecionados : props.codigoSelecionado);
    }

    return {
      props,
      state,
      pesquisarCEST,
      computedCodigoSelecionado,
      computedCodigosSelecionados,
      change,
    };
  },
});
