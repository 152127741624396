
import { computed, defineComponent } from 'vue';
import Card from '@/core/components/Tela/Card.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import { IProdutoCombustivel, IProdutoMedicamento } from '@/models/Entidades/Cadastros/Produtos/IProduto';

export default defineComponent({
  name: 'ProdutoDadosEspecificos',
  props: {
    medicamento: {
      type: Boolean,
      required: true,
    },
    produtoMedicamento: {
      type: Object as () => IProdutoMedicamento,
      required: true,
    },
    combustivel: {
      type: Boolean,
      required: true,
    },
    produtoCombustivel: {
      type: Object as () => IProdutoCombustivel,
      required: true,
    },
  },
  components: {
    Card,
    CampoNumerico,
  },
  emits: ['update:codigoPlanoContaCategoria', 'update:medicamento', 'update:produtoMedicamento',
    'update:combustivel', 'update:produtoCombustivel'],
  setup(props, { emit }) {
    const computedMedicamento = computed({
      get: () => props.medicamento,
      set: (valor: boolean) => {
        emit('update:medicamento', valor);
      },
    });

    const computedProdutoMedicamento = computed({
      get: () => props.produtoMedicamento,
      set: (valor: IProdutoMedicamento) => {
        emit('update:produtoMedicamento', valor);
      },
    });

    const computedCombustivel = computed({
      get: () => props.combustivel,
      set: (valor: boolean) => {
        emit('update:combustivel', valor);
      },
    });

    const computedProdutoCombustivel = computed({
      get: () => props.produtoCombustivel,
      set: (valor: IProdutoCombustivel) => {
        emit('update:produtoCombustivel', valor);
      },
    });

    return {
      props,
      computedMedicamento,
      computedProdutoMedicamento,
      computedCombustivel,
      computedProdutoCombustivel,
    };
  },
});
