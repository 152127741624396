import ApiERP from '@/core/conectores/ApiERP';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IGrupoParametros } from '@/models/Entidades/MeuSistema/Parametros/IGrupoParametros';
import { IParametro, IParametroValor } from '@/models/Entidades/MeuSistema/Parametros/IParametro';
/**
 * Serviço de Parâmetros
 * Fornece todas regras de negócios e lógicas relacionado aos Parâmetros.
 */
class ServicoParametros {
  endPoint = 'controle-parametros';

  apiERP = new ApiERP();

  public requisicaoSistema():void {
    this.apiERP = new ApiERP(true);
  }

  public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
    const listaPropriedades = await this.apiERP.obterPropriedadesConsulta(this.endPoint);
    return listaPropriedades;
  }

  public async buscaAvancada(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
    return this.apiERP.buscaAvancada(this.endPoint, parametrosConsulta, filtros);
  }

  public async obterGruposParametros(empresa: number): Promise<IGrupoParametros[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/parametros-grupo/empresa/${empresa}`);
    return result.data;
  }

  public async obterParametrosGrupos(empresa: number, grupo: number): Promise<IParametro[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/parametros-grupo/${grupo}/empresa/${empresa}`);
    return result.data;
  }

  public async obterParametro(empresa: number, identificador: string): Promise<IParametro> {
    const result: any = await this.apiERP.get(`${this.endPoint}/${identificador}/empresa/${empresa}`);
    return result.data;
  }

  public async obterValorPadrao(empresa: number, identificador: string): Promise<IParametroValor> {
    const result: any = await this.apiERP.get(`${this.endPoint}/valor-padrao/${identificador}/empresa/${empresa}`);
    return result.data;
  }

  public async obterValor(empresa: number, identificador: string): Promise<IParametroValor> {
    const result: any = await this.apiERP.get(`${this.endPoint}/valor/${identificador}/empresa/${empresa}`);
    return result.data;
  }

  public async atualizarValores(empresa: number, parametros: IParametro[]): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.put(`${this.endPoint}/atualizar-valores/${empresa}`, parametros);
    return result.data;
  }
}
export default ServicoParametros;
