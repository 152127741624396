
import {
  computed, defineComponent, reactive, ref, watch,
} from 'vue';
import { IOption } from '@/core/models/AntDesign/IOption';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { useTelaBase } from '@/core/composables/TelaBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import storeSistema from '@/store/storeSistema';
import ServicoProduto from '@/servicos/Cadastros/Produtos/ServicoProduto';

export default defineComponent({
  name: 'SelecionarProduto',
  props: {
    modoDefinicaoProduto: {
      type: Boolean,
      default: false,
    },
    varios: {
      type: Boolean,
      default: false,
    },
    codigoSelecionado: {
      type: Number || undefined,
    },
    codigoInternoSelecionado: {
      type: String,
      default: '',
    },
    codigosSelecionados: {
      type: Array as () => number[],
      default: () => [],
    },
    descricaoSelecionado: {
      type: String,
      default: '',
    },
    empresas: {
      type: Array as () => number[],
      required: true,
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    dataAttributeColuna: {
      type: String,
      default: '',
    },
    classCss: {
      type: String,
      default: '',
    },
    limparSelecao: {
      type: Boolean,
      default: true,
    },
    apresentarCodigoInterno: {
      type: Boolean,
      default: false,
    },
    codigoParaBusca: {
      type: String,
      default: '',
    },
  },
  emits: ['update:codigoSelecionado', 'update:codigosSelecionados', 'update:descricaoSelecionado', 'update:codigoParaBusca', 'update:codigoInternoSelecionado', 'change', 'blur'],
  setup(props, { emit }) {
    const { montaOpcoesComListaConsultaRapida, apresentarMensagemAlerta } = useTelaBase();
    const refSelecionarProduto = ref<HTMLElement | null>(null);
    let debounce = 0;
    const servicoProduto = new ServicoProduto();

    const state = reactive({
      listaProdutos: [] as IOption[],
      buscandoDados: false,
      emDigitacao: false,
      computedCodigoParaBusca: computed({
        get: () => props.codigoParaBusca,
        set: (val: string) => {
          emit('update:codigoParaBusca', val);
        },
      }),
    });

    function change(codigoSelecionado: number) {
      emit('change', codigoSelecionado);
    }

    function changeVarios(codigosSelecionados: number[]) {
      emit('change', codigosSelecionados);
    }

    async function obterProdutoPorCodigo(valor: any) {
      if (state.emDigitacao) { return; }

      if (props.varios) {
        if (props.codigosSelecionados.length === 0) { return; }
      } else {
        if (!UtilitarioGeral.valorValido(valor)) { return; }
        if (!(valor > 0)) { return; }
        if (props.codigoSelecionado === 0) return;
      }
      let efetuarConsulta = true;

      if (UtilitarioGeral.validaLista(state.listaProdutos)) {
        if (props.varios) {
          const listaCodigosConsulta = state.listaProdutos.map((c) => c.value);
          const diferencas = props.codigosSelecionados.filter((c) => !listaCodigosConsulta.includes(c));
          if (!UtilitarioGeral.validaLista(diferencas)) {
            efetuarConsulta = false;
          }
        } else {
          const existeLista = state.listaProdutos.find((c) => c.value === props.codigoSelecionado);
          if (existeLista !== undefined) {
            efetuarConsulta = false;
          }
        }
      }

      if (!efetuarConsulta) return;

      state.listaProdutos = [];

      const parametrosConsultaRapida: IParametrosConsultaRapida = {
        apenasAtivos: false, recursoAssociado: '',
      };

      if (UtilitarioGeral.validaLista(props.empresas)) {
        parametrosConsultaRapida.empresas = props.empresas;
      } else {
        parametrosConsultaRapida.empresas = storeSistema.getters.empresasUsuarioAutenticado();
      }

      if (props.varios) {
        parametrosConsultaRapida.filtrarPorVariosCodigos = true;
        parametrosConsultaRapida.valores = valor;
      } else {
        parametrosConsultaRapida.filtrarPorCodigo = true;
        parametrosConsultaRapida.valor = valor;
      }
      let listaProdutos = [];
      if (props.modoDefinicaoProduto) {
        listaProdutos = await servicoProduto.consultaRapidaDefinicoes(parametrosConsultaRapida, props.apresentarCodigoInterno);
      } else {
        listaProdutos = await servicoProduto.consultaRapida(parametrosConsultaRapida, props.apresentarCodigoInterno);
      }
      state.listaProdutos = montaOpcoesComListaConsultaRapida(listaProdutos);
    }

    function obterProduto():any {
      obterProdutoPorCodigo(props.codigoSelecionado);
      return (props.codigoSelecionado === 0 ? undefined : props.codigoSelecionado);
    }

    const computedCodigoInternoSelecionado = computed({
      get: () => props.codigoInternoSelecionado,
      set: (val: string) => {
        emit('update:codigoInternoSelecionado', val);
      },
    });

    const computedDescricaoSelecionado = computed({
      get: () => props.descricaoSelecionado,
      set: (val: string) => {
        emit('update:descricaoSelecionado', val);
      },
    });

    const computedCodigoSelecionado = computed({
      get: () => obterProduto(),
      set: (val: number) => {
        emit('update:codigoSelecionado', val);
        const option = state.listaProdutos.find((c) => c.value === val);
        if (option?.label !== undefined && option?.label !== '') {
          computedDescricaoSelecionado.value = option?.label;
          const divisao = option?.label.split('-');
          computedCodigoInternoSelecionado.value = divisao[0].trim();
        }
      },
    });

    async function pesquisarProduto(valorPesquisa: any, buscaPorCodigo?:boolean) {
      state.emDigitacao = true;

      // if (!UtilitarioGeral.valorValido(valorPesquisa)) { return; }

      state.buscandoDados = true;
      state.listaProdutos = [];
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        const parametrosConsultaRapida: IParametrosConsultaRapida = {
          valor: valorPesquisa, apenasAtivos: true, recursoAssociado: '',
        };

        if (UtilitarioGeral.validaLista(props.empresas)) {
          parametrosConsultaRapida.empresas = props.empresas;
        } else {
          parametrosConsultaRapida.empresas = storeSistema.getters.empresasUsuarioAutenticado();
        }

        let listaProdutos = [];
        if (props.modoDefinicaoProduto) {
          listaProdutos = await servicoProduto.consultaRapidaDefinicoes(parametrosConsultaRapida, props.apresentarCodigoInterno);
        } else {
          listaProdutos = await servicoProduto.consultaRapida(parametrosConsultaRapida, props.apresentarCodigoInterno);
        }
        state.buscandoDados = false;
        state.listaProdutos = montaOpcoesComListaConsultaRapida(listaProdutos);

        if (UtilitarioGeral.validaBoleano(buscaPorCodigo)) {
          if (buscaPorCodigo && UtilitarioGeral.validaLista(state.listaProdutos)) {
            computedCodigoSelecionado.value = state.listaProdutos[0].value;
            change(state.listaProdutos[0].value);
          } else {
            apresentarMensagemAlerta(`Nenhum produto foi encontrado com o código: ${valorPesquisa}`);
          }
        }
      }, 600);
    }

    function obterProdutos():number[] {
      obterProdutoPorCodigo(props.codigosSelecionados);
      return props.codigosSelecionados;
    }

    const computedCodigosSelecionados = computed({
      get: () => obterProdutos(),
      set: (val: number[]) => {
        emit('update:codigosSelecionados', val);
      },
    });

    function focus() {
      if (refSelecionarProduto.value) {
        refSelecionarProduto.value.focus();
      }
    }

    function blur() {
      state.emDigitacao = false;
      emit('blur');
    }

    watch(async () => state.computedCodigoParaBusca, async () => {
      if (UtilitarioGeral.valorValido(state.computedCodigoParaBusca)) {
        await pesquisarProduto(state.computedCodigoParaBusca, true);
        state.computedCodigoParaBusca = '';
      }
    });

    return {
      props,
      state,
      refSelecionarProduto,
      pesquisarProduto,
      computedCodigoSelecionado,
      computedCodigosSelecionados,
      computedCodigoInternoSelecionado,
      change,
      changeVarios,
      focus,
      blur,
    };
  },
});
