import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_select_option = _resolveComponent("a-select-option")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.props.varios)
      ? (_openBlock(), _createBlock(_component_a_select, {
          key: 0,
          class: "ss-tamanho-completo",
          ref: "refSelecionarCaracteristica",
          value: _ctx.computedCodigosSelecionados,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedCodigosSelecionados) = $event)),
          "show-search": "",
          mode: "multiple",
          allowClear: true,
          placeholder: _ctx.props.placeholder,
          optionFilterProp: "label",
          "not-found-content": _ctx.state.buscandoDados ? undefined : null,
          options: _ctx.state.listaCaracteristicas,
          disabled: _ctx.props.disabled,
          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.change($event))),
          onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.pesquisarCaracteristica(''))),
          onSearch: _cache[3] || (_cache[3] = ($event: any) => (_ctx.pesquisarCaracteristica($event))),
          onBlur: _cache[4] || (_cache[4] = ($event: any) => (_ctx.state.emDigitacao = false))
        }, _createSlots({ _: 2 }, [
          (_ctx.state.buscandoDados)
            ? {
                name: "notFoundContent",
                fn: _withCtx(() => [
                  _createTextVNode(" Buscando Características "),
                  _createVNode(_component_a_spin)
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["value", "placeholder", "not-found-content", "options", "disabled"]))
      : (_openBlock(), _createBlock(_component_a_select, {
          key: 1,
          class: "ss-tamanho-completo",
          ref: "refSelecionarCaracteristica",
          value: _ctx.computedCodigoSelecionado,
          "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.computedCodigoSelecionado) = $event)),
          "show-search": "",
          allowClear: true,
          placeholder: _ctx.props.placeholder,
          optionFilterProp: "label",
          "not-found-content": _ctx.state.buscandoDados ? undefined : null,
          disabled: _ctx.props.disabled,
          onChange: _ctx.change,
          onFocus: _cache[6] || (_cache[6] = ($event: any) => (_ctx.pesquisarCaracteristica(''))),
          onSearch: _cache[7] || (_cache[7] = ($event: any) => (_ctx.pesquisarCaracteristica($event))),
          onBlur: _cache[8] || (_cache[8] = ($event: any) => (_ctx.state.emDigitacao = false))
        }, _createSlots({
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.listaCaracteristicas, (item, index) => {
              return (_openBlock(), _createBlock(_component_a_select_option, {
                key: index,
                value: item.codigo,
                label: ""
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    style: _normalizeStyle('background-color: ' + item.informacaoAdicional + '; color: ' + item.informacaoAdicional + '; border-radius: 5px; width: 100%;')
                  }, _toDisplayString(item.informacaoAdicional), 5)
                ]),
                _: 2
              }, 1032, ["value"]))
            }), 128))
          ]),
          _: 2
        }, [
          (_ctx.state.buscandoDados)
            ? {
                name: "notFoundContent",
                fn: _withCtx(() => [
                  _createTextVNode(" Buscando Características "),
                  _createVNode(_component_a_spin)
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["value", "placeholder", "not-found-content", "disabled", "onChange"]))
  ]))
}