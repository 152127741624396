
import {
  computed, defineComponent, reactive, ref,
} from 'vue';
import draggable from 'vuedraggable';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import SelecionarSimNao from '@/core/components/Tela/SelecionarSimNao.vue';
import SelecionarCaracteristica from '@/components/Cadastros/Produtos/Caracteristicas/SelecionarCaracteristica.vue';
import SelecionarCaracteristicaItem from '@/components/Cadastros/Produtos/Caracteristicas/SelecionarCaracteristicaItem.vue';
import SelecionarCaracteristicaSubItem from '@/components/Cadastros/Produtos/Caracteristicas/SelecionarCaracteristicaSubItem.vue';
import SelecionarCaracteristicaCor from './Caracteristicas/SelecionarCaracteristicaCor.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IProdutoCaracteristica } from '@/models/Entidades/Cadastros/Produtos/IProduto';
import { ETipoCaracteristica } from '@/models/Enumeradores/Cadastros/Produtos/ETipoCaracteristica';
import ServicoCaracteristica from '@/servicos/Cadastros/Produtos/ServicoCaracteristica';

export default defineComponent({
  name: 'ProdutoCaracteristicas',
  props: {
    empresas: {
      type: Array as () => number[],
      required: true,
    },
    caracteristicas: {
      type: Array as () => IProdutoCaracteristica[],
      required: true,
    },
  },
  components: {
    draggable,
    Icone,
    MensagemSemDados,
    SelecionarSimNao,
    SelecionarCaracteristica,
    SelecionarCaracteristicaItem,
    SelecionarCaracteristicaSubItem,
    SelecionarCaracteristicaCor,
  },
  emits: ['update:caracteristicas'],
  setup(props, { emit }) {
    const { apresentarMensagemAlerta } = useTelaBase();

    const campoNovaCaracteristica = ref<HTMLElement | null>(null);
    const campoCodigoNovaCaracteristica = ref<HTMLElement | null>(null);
    const servicoCaracteristica = new ServicoCaracteristica();

    const state = reactive({
      novaCaracteristica: {} as IProdutoCaracteristica,
      carregandoCaracteristica: false,
    });

    const computedCaracteristicas = computed({
      get: () => props.caracteristicas,
      set: (valor: IProdutoCaracteristica[]) => {
        emit('update:caracteristicas', valor);
      },
    });

    function verificaPreenchimentoCampoValor(tipoCaracteristica: number): boolean {
      if (tipoCaracteristica === ETipoCaracteristica.TextoLivre
        || tipoCaracteristica === ETipoCaracteristica.Numero
        || tipoCaracteristica === ETipoCaracteristica.Decimal) {
        return true;
      }

      return false;
    }

    function verificaPreenchimentoItem(tipoCaracteristica: number): boolean {
      if (tipoCaracteristica === ETipoCaracteristica.ListaSimples) {
        return true;
      }

      return false;
    }

    function verificaPreenchimentoSubItem(tipoCaracteristica: number): boolean {
      if (tipoCaracteristica === ETipoCaracteristica.ListaSubItens) {
        return true;
      }

      return false;
    }

    async function obterDadosCaracteristica(codigoCaracteristica: number) {
      state.carregandoCaracteristica = true;
      state.novaCaracteristica.tipo = await servicoCaracteristica.obterTipoCaracteristica(codigoCaracteristica);
      state.carregandoCaracteristica = false;
    }
    function objetoInicialCaracteristica() {
      state.novaCaracteristica = {} as IProdutoCaracteristica;
      state.novaCaracteristica.codigo = 0;
      state.novaCaracteristica.codigoProduto = 0;
      state.novaCaracteristica.caracteristicaPersonalizada = '';
      state.novaCaracteristica.variacao = false;
    }
    function removerCaracteristica(index: number) {
      computedCaracteristicas.value.splice(index, 1);
    }
    function focoNovaCaracteristica() {
      if (campoNovaCaracteristica.value) {
        campoNovaCaracteristica.value.focus();
      }
    }
    function focoValorNovaCaracteristica() {
      if (campoCodigoNovaCaracteristica.value) {
        campoCodigoNovaCaracteristica.value.focus();
      }
    }

    function adicionarCaracteristica() {
      if (!UtilitarioGeral.valorValido(state.novaCaracteristica.codigoCaracteristica)) {
        apresentarMensagemAlerta('Por favor, selecione uma característica!');
        focoNovaCaracteristica();
        return;
      }
      if (state.novaCaracteristica.tipo === ETipoCaracteristica.TextoLivre
        || state.novaCaracteristica.tipo === ETipoCaracteristica.Numero
        || state.novaCaracteristica.tipo === ETipoCaracteristica.Decimal) {
        if (!UtilitarioGeral.valorValido(state.novaCaracteristica.caracteristicaPersonalizada)) {
          apresentarMensagemAlerta('Por favor, informe um valor valido!');
          focoValorNovaCaracteristica();
          return;
        }
      } else if (state.novaCaracteristica.tipo === ETipoCaracteristica.Cor
        || state.novaCaracteristica.tipo === ETipoCaracteristica.ListaSimples) {
        if (!UtilitarioGeral.valorValido(state.novaCaracteristica.codigoCaracteristicaItem)) {
          apresentarMensagemAlerta('Por favor, selecione um item da característica!');
          return;
        }

        if (state.novaCaracteristica.codigoCaracteristicaItem <= 0) {
          apresentarMensagemAlerta('Por favor, selecione um item da característica!');
          return;
        }
      } else if (state.novaCaracteristica.tipo === ETipoCaracteristica.ListaSubItens) {
        if (!UtilitarioGeral.valorValido(state.novaCaracteristica.codigoCaracteristicaItem)) {
          apresentarMensagemAlerta('Por favor, selecione um item da característica!');
          return;
        }

        if (state.novaCaracteristica.codigoCaracteristicaItem <= 0) {
          apresentarMensagemAlerta('Por favor, selecione um item da característica!');
          return;
        }

        if (!UtilitarioGeral.valorValido(state.novaCaracteristica.codigoCaracteristicaSubItem)) {
          apresentarMensagemAlerta('Por favor, selecione um sub item da característica!');
          return;
        }

        if (state.novaCaracteristica.codigoCaracteristicaSubItem <= 0) {
          apresentarMensagemAlerta('Por favor, selecione um sub item da característica!');
          return;
        }
      }

      if (UtilitarioGeral.validaLista(computedCaracteristicas.value)) {
        if (state.novaCaracteristica.tipo === ETipoCaracteristica.Cor || state.novaCaracteristica.tipo === ETipoCaracteristica.ListaSimples) {
          const produtoCaracteristica = computedCaracteristicas.value.find((c) => c.codigoCaracteristica === state.novaCaracteristica.codigoCaracteristica && c.codigoCaracteristicaItem === state.novaCaracteristica.codigoCaracteristicaItem);
          if (UtilitarioGeral.objetoValido(produtoCaracteristica)) {
            apresentarMensagemAlerta('A característica informada já foi adicionada!');
            focoNovaCaracteristica();
            return;
          }
        } else if (state.novaCaracteristica.tipo === ETipoCaracteristica.ListaSubItens) {
          const produtoCaracteristica = computedCaracteristicas.value.find((c) => c.codigoCaracteristica === state.novaCaracteristica.codigoCaracteristica
            && c.codigoCaracteristicaItem === state.novaCaracteristica.codigoCaracteristicaItem
            && c.codigoCaracteristicaSubItem === state.novaCaracteristica.codigoCaracteristicaSubItem);
          if (UtilitarioGeral.objetoValido(produtoCaracteristica)) {
            apresentarMensagemAlerta('A característica com sub item informada já foi adicionada!');
            focoNovaCaracteristica();
            return;
          }
        }
      }

      const produtoCaracteristica: IProdutoCaracteristica = {} as IProdutoCaracteristica;
      produtoCaracteristica.codigo = 0;
      produtoCaracteristica.codigoProduto = 0;
      produtoCaracteristica.codigoProdutoDefinicao = 0;
      produtoCaracteristica.codigoCaracteristica = state.novaCaracteristica.codigoCaracteristica;
      produtoCaracteristica.tipo = state.novaCaracteristica.tipo;
      produtoCaracteristica.codigoCaracteristicaItem = 0;
      produtoCaracteristica.codigoCaracteristicaSubItem = 0;
      produtoCaracteristica.imprimirVendaNfe = false;
      if (state.novaCaracteristica.tipo === ETipoCaracteristica.TextoLivre
        || state.novaCaracteristica.tipo === ETipoCaracteristica.Numero
        || state.novaCaracteristica.tipo === ETipoCaracteristica.Decimal) {
        produtoCaracteristica.caracteristicaPersonalizada = state.novaCaracteristica.caracteristicaPersonalizada;
      }

      if (state.novaCaracteristica.tipo === ETipoCaracteristica.ListaSimples
        || state.novaCaracteristica.tipo === ETipoCaracteristica.ListaSubItens
        || state.novaCaracteristica.tipo === ETipoCaracteristica.Cor) {
        produtoCaracteristica.codigoCaracteristicaItem = state.novaCaracteristica.codigoCaracteristicaItem;
      }

      if (state.novaCaracteristica.tipo === ETipoCaracteristica.ListaSubItens) {
        produtoCaracteristica.codigoCaracteristicaSubItem = state.novaCaracteristica.codigoCaracteristicaSubItem;
      }

      computedCaracteristicas.value.push(produtoCaracteristica);
      objetoInicialCaracteristica();
      focoNovaCaracteristica();
    }

    return {
      props,
      ETipoCaracteristica,
      computedCaracteristicas,
      state,
      campoNovaCaracteristica,
      campoCodigoNovaCaracteristica,
      verificaPreenchimentoCampoValor,
      verificaPreenchimentoItem,
      verificaPreenchimentoSubItem,
      obterDadosCaracteristica,
      focoValorNovaCaracteristica,
      adicionarCaracteristica,
      removerCaracteristica,
    };
  },
});
