
import {
  computed, defineComponent, reactive, ref,
} from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import SelecionarProduto from '@/components/Cadastros/Produtos/SelecionarProduto.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IProdutoSemelhante } from '@/models/Entidades/Cadastros/Produtos/IProduto';
import Icone from '@/core/components/Icone.vue';

export default defineComponent({
  name: 'ProdutoSemelhantes',
  props: {
    semelhantes: {
      type: Array as () => IProdutoSemelhante[],
      required: true,
    },
    empresas: {
      type: Array as () => number[],
      required: true,
    },
  },
  components: {
    MensagemSemDados,
    SelecionarProduto,
    Icone,
  },
  emits: ['update:semelhantes'],
  setup(props, { emit }) {
    const { apresentarMensagemAlerta } = useTelaBase();

    const campoNovoSemelhante = ref<HTMLElement | null>(null);

    const state = reactive({
      novosSemelhantes: [] as number[],
    });

    state.novosSemelhantes = [];
    const computedSemelhantes = computed({
      get: () => props.semelhantes,
      set: (valor: IProdutoSemelhante[]) => {
        emit('update:semelhantes', valor);
      },
    });

    function removerSemelhante(index: number) {
      computedSemelhantes.value.splice(index, 1);
    }
    function focoNovosSemelhantes() {
      if (campoNovoSemelhante.value) {
        campoNovoSemelhante.value.focus();
      }
    }

    function adicionarSemelhante() {
      if (!UtilitarioGeral.validaLista(state.novosSemelhantes)) {
        apresentarMensagemAlerta('Por favor, selecione pelo menos um produto!');
        focoNovosSemelhantes();
        return;
      }

      state.novosSemelhantes.forEach((codigoProduto) => {
        if (!computedSemelhantes.value.some((c) => c.codigoProdutoSemelhante === codigoProduto)) {
          const produtoSemelhante:IProdutoSemelhante = {} as IProdutoSemelhante;
          produtoSemelhante.codigo = 0;
          produtoSemelhante.codigoProduto = 0;
          produtoSemelhante.codigoProdutoSemelhante = codigoProduto;
          computedSemelhantes.value.push(produtoSemelhante);
        }
      });

      state.novosSemelhantes = [];
      focoNovosSemelhantes();
    }

    return {
      props,
      computedSemelhantes,
      state,
      campoNovoSemelhante,
      adicionarSemelhante,
      removerSemelhante,
    };
  },
});
