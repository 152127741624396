import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IDTOProduto } from '@/models/DTO/Cadastros/Produtos/IDTOProduto';

/**
 * Serviço importação de produtos
 * Fornece todas regras de negócios e lógicas relacionado a importação de produtos.
 */
class ServicoImportacaoProdutos {
    endPoint = 'importacao-produtos';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async obterArquivoPadraoProdutos(): Promise<string> {
      const result: any = await this.apiERP.get(`${this.endPoint}/produtos/arquivo-template`);
      return result.data;
    }

    public async lerArquivoProdutos(empresa: number, arquivo: any): Promise<IDTOProduto[]> {
      const data = new FormData();
      data.append('arquivo', arquivo);

      const result: any = await this.apiERP.upload(`${this.endPoint}/produtos/ler-arquivo/${empresa}`, data);
      return result.data;
    }

    public async importarProdutos(empresa: number, produtos: IDTOProduto[]): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/produtos/importar/${empresa}`, produtos);
      return result.data;
    }
}
export default ServicoImportacaoProdutos;
