import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, vShow as _vShow, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ss-tamanho-completo" }
const _hoisted_2 = { class: "ant-row" }
const _hoisted_3 = { class: "ant-col ant-col-xs-24 ant-col-xl-8" }
const _hoisted_4 = { class: "ant-col ant-col-xs-24 ant-col-xl-10" }
const _hoisted_5 = { class: "ant-row" }
const _hoisted_6 = { class: "ant-col ant-col-xs-24 ant-col-xl-4" }
const _hoisted_7 = { class: "ant-col ant-col-xs-24 ant-col-xl-14" }
const _hoisted_8 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }
const _hoisted_9 = { class: "ant-row" }
const _hoisted_10 = { class: "ant-col ant-col-xs-24 ant-col-xl-4" }
const _hoisted_11 = { class: "ant-col ant-col-xs-24 ant-col-xl-5" }
const _hoisted_12 = { class: "ant-col ant-col-xs-24 ant-col-xl-5" }
const _hoisted_13 = { class: "ant-col ant-col-xs-24 ant-col-xl-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_campo_numerico = _resolveComponent("campo-numerico")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_card, { titulo: "" }, {
      default: _withCtx(() => [
        _createVNode(_component_a_checkbox, {
          class: "ss-check-card",
          checked: _ctx.computedMedicamento,
          "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedMedicamento) = $event))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Medicamento ")
          ]),
          _: 1
        }, 8, ["checked"]),
        _withDirectives(_createVNode(_component_a_form, {
          class: "ss-separador-card",
          layout: "vertical"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_a_form_item, {
                  label: "Código ANVISA",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "ant-input",
                      maxlength: "13",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedProdutoMedicamento.codigoAnvisa) = $event))
                    }, null, 512), [
                      [_vModelText, _ctx.computedProdutoMedicamento.codigoAnvisa]
                    ])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_a_form_item, {
                  label: "Preço máximo consumidor - PMC",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_campo_numerico, {
                      monetario: true,
                      valor: _ctx.computedProdutoMedicamento.precoMaximoConsumidor,
                      "onUpdate:valor": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedProdutoMedicamento.precoMaximoConsumidor) = $event))
                    }, null, 8, ["valor"])
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _: 1
        }, 512), [
          [_vShow, _ctx.computedMedicamento]
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_card, { titulo: "" }, {
      default: _withCtx(() => [
        _createVNode(_component_a_checkbox, {
          class: "ss-check-card",
          checked: _ctx.computedCombustivel,
          "onUpdate:checked": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.computedCombustivel) = $event))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Combustível ")
          ]),
          _: 1
        }, 8, ["checked"]),
        _withDirectives(_createVNode(_component_a_form, {
          class: "ss-separador-card",
          layout: "vertical"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_a_form_item, {
                  label: "Código ANP",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "ant-input",
                      maxlength: "10",
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.computedProdutoCombustivel.codigoAnp) = $event))
                    }, null, 512), [
                      [_vModelText, _ctx.computedProdutoCombustivel.codigoAnp]
                    ])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_a_form_item, {
                  label: "Descrição ANP",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "ant-input",
                      maxlength: "250",
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.computedProdutoCombustivel.descricaoAnp) = $event))
                    }, null, 512), [
                      [_vModelText, _ctx.computedProdutoCombustivel.descricaoAnp]
                    ])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_a_form_item, {
                  label: "Código de autorização - CODIF",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "ant-input",
                      maxlength: "21",
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.computedProdutoCombustivel.autorizacaoCodif) = $event))
                    }, null, 512), [
                      [_vModelText, _ctx.computedProdutoCombustivel.autorizacaoCodif]
                    ])
                  ]),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_a_form_item, {
                  label: "Percentual do GLP",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_campo_numerico, {
                      percentual: true,
                      valor: _ctx.computedProdutoCombustivel.percentualGlp,
                      "onUpdate:valor": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.computedProdutoCombustivel.percentualGlp) = $event))
                    }, null, 8, ["valor"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_a_form_item, {
                  label: "Percentual de Gás Natural Nacional",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_campo_numerico, {
                      percentual: true,
                      valor: _ctx.computedProdutoCombustivel.percentualGnn,
                      "onUpdate:valor": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.computedProdutoCombustivel.percentualGnn) = $event))
                    }, null, 8, ["valor"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_a_form_item, {
                  label: "Percentual de Gás Natural Importado",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_campo_numerico, {
                      percentual: true,
                      valor: _ctx.computedProdutoCombustivel.percentualGn,
                      "onUpdate:valor": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.computedProdutoCombustivel.percentualGn) = $event))
                    }, null, 8, ["valor"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_a_form_item, {
                  label: "Valor Partida",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_campo_numerico, {
                      monetario: true,
                      valor: _ctx.computedProdutoCombustivel.valorPartida,
                      "onUpdate:valor": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.computedProdutoCombustivel.valorPartida) = $event))
                    }, null, 8, ["valor"])
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _: 1
        }, 512), [
          [_vShow, _ctx.computedCombustivel]
        ])
      ]),
      _: 1
    })
  ]))
}