
import {
  computed, defineComponent, reactive, ref,
} from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import SelecionarFornecedor from '@/components/Cadastros/Pessoas/Fornecedores/SelecionarFornecedor.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IProdutoFornecedor } from '@/models/Entidades/Cadastros/Produtos/IProduto';
import Icone from '@/core/components/Icone.vue';

export default defineComponent({
  name: 'ProdutoFornecedores',
  props: {
    fornecedores: {
      type: Array as () => IProdutoFornecedor[],
      required: true,
    },
    empresas: {
      type: Array as () => number[],
      required: true,
    },
  },
  components: {
    MensagemSemDados,
    SelecionarFornecedor,
    Icone,
  },
  emits: ['update:fornecedores'],
  setup(props, { emit }) {
    const { apresentarMensagemAlerta } = useTelaBase();

    const campoNovoFornecedor = ref<HTMLElement | null>(null);
    const campoCodigoNovoFornecedor = ref<HTMLElement | null>(null);

    const state = reactive({
      novoFornecedor: {} as IProdutoFornecedor,
    });

    const computedFornecedores = computed({
      get: () => props.fornecedores,
      set: (valor: IProdutoFornecedor[]) => {
        emit('update:fornecedores', valor);
      },
    });

    function objetoInicialFornecedor() {
      state.novoFornecedor = {} as IProdutoFornecedor;
      state.novoFornecedor.codigo = 0;
      state.novoFornecedor.codigoProdutoDefinicao = 0;
      state.novoFornecedor.identificador = '';
      state.novoFornecedor.principal = false;
    }
    function removerFornecedor(index: number) {
      computedFornecedores.value.splice(index, 1);
    }
    function focoNovoFornecedor() {
      if (campoNovoFornecedor.value) {
        campoNovoFornecedor.value.focus();
      }
    }
    function focoCodigoNovoFornecedor() {
      if (campoCodigoNovoFornecedor.value) {
        campoCodigoNovoFornecedor.value.focus();
      }
    }

    function defineApenasUmPrincipal(index:number, valor: boolean, novoItem:boolean) {
      if (valor) {
        if (UtilitarioGeral.validaLista(computedFornecedores.value)) {
          for (let i = 0; i < computedFornecedores.value.length; (i += 1)) {
            if (i !== index || novoItem) {
              computedFornecedores.value[i].principal = false;
            }
          }
        }
      }
    }
    function adicionarFornecedor() {
      if (UtilitarioGeral.validaLista(computedFornecedores.value)) {
        const produtoFornecedor = computedFornecedores.value.find((c) => c.codigoFornecedor === state.novoFornecedor.codigoFornecedor && c.identificador === state.novoFornecedor.identificador);
        if (UtilitarioGeral.valorValido(produtoFornecedor)) {
          apresentarMensagemAlerta('O Fornecedor e código informado já foi adicionado!');
          focoNovoFornecedor();
          return;
        }
      }

      const fornecedorProduto:IProdutoFornecedor = {} as IProdutoFornecedor;
      fornecedorProduto.codigo = 0;
      fornecedorProduto.codigoFornecedor = state.novoFornecedor.codigoProdutoDefinicao;
      fornecedorProduto.codigoFornecedor = state.novoFornecedor.codigoFornecedor;
      fornecedorProduto.identificador = state.novoFornecedor.identificador;
      fornecedorProduto.principal = (!UtilitarioGeral.validaLista(computedFornecedores.value));

      computedFornecedores.value.push(fornecedorProduto);
      objetoInicialFornecedor();
      focoNovoFornecedor();
    }

    return {
      props,
      computedFornecedores,
      state,
      campoNovoFornecedor,
      campoCodigoNovoFornecedor,
      focoCodigoNovoFornecedor,
      adicionarFornecedor,
      removerFornecedor,
      defineApenasUmPrincipal,
    };
  },
});
