
import { computed, defineComponent, reactive } from 'vue';
import Card from '@/core/components/Tela/Card.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { ETipoItem } from '@/models/Enumeradores/Compartilhados/ETipoItem';
import { IProdutoCusto } from '@/models/Entidades/Cadastros/Produtos/IProduto';
import ServicoTipoCusto from '@/servicos/Cadastros/Precificacao/ServicoTipoCusto';
import { ITipoCusto } from '@/models/Entidades/Cadastros/Precificacao/ITipoCusto';

export default defineComponent({
  name: 'ProdutoCustos',
  props: {
    custos: {
      type: Array as () => IProdutoCusto[],
      required: true,
    },
    codigoProdutoDefinicao: {
      type: Number,
      required: true,
    },
    empresas: {
      type: Array as () => number[],
      required: true,
    },
  },
  components: {
    CampoNumerico,
    SelecionarData,
    Card,
    MensagemSemDados,
  },
  emits: ['update:custos'],
  setup(props, { emit }) {
    const servicoTipoCusto = new ServicoTipoCusto();
    const computedCustos = computed({
      get: () => props.custos,
      set: (valor: IProdutoCusto[]) => {
        emit('update:custos', valor);
      },
    });

    const state = reactive({
      tipoCustosApresentacao: [] as ITipoCusto[],
      preparandoCustos: false,
    });

    state.tipoCustosApresentacao = [];

    function criaCustoProdutoDefinicao(codigoTipoCusto:number) {
      const custoProduto:IProdutoCusto = {} as IProdutoCusto;
      custoProduto.codigo = 0;
      custoProduto.codigoProdutoDefinicao = props.codigoProdutoDefinicao;
      custoProduto.codigoTipoCusto = codigoTipoCusto;
      custoProduto.valor = 0;
      custoProduto.dataAtualizacao = '';

      return custoProduto;
    }
    function preparaApresentacaoCustos() {
      let custosProduto:IProdutoCusto[] = [];
      state.preparandoCustos = true;

      if (UtilitarioGeral.validaLista(computedCustos.value)) {
        custosProduto = UtilitarioGeral.instanciaObjetoLocal(computedCustos.value);
      }
      let criouCustoProduto = false;

      if (UtilitarioGeral.validaLista(state.tipoCustosApresentacao)) {
        state.tipoCustosApresentacao.forEach((tipoCustosApresentacao) => {
          if (UtilitarioGeral.validaLista(custosProduto)) {
            if (!custosProduto.some((c) => c.codigoProdutoDefinicao === props.codigoProdutoDefinicao
                                                && c.codigoTipoCusto === tipoCustosApresentacao.codigo)) {
              custosProduto.push(criaCustoProdutoDefinicao(tipoCustosApresentacao.codigo));
              criouCustoProduto = true;
            }
          } else {
            custosProduto.push(criaCustoProdutoDefinicao(tipoCustosApresentacao.codigo));
            criouCustoProduto = true;
          }
        });
      }
      if (criouCustoProduto) { computedCustos.value = custosProduto; }
      state.preparandoCustos = false;
    }

    (async () => {
      state.tipoCustosApresentacao = await servicoTipoCusto.obterTiposCustosPorEmpresaTipoItem(props.empresas, ETipoItem.Produto, true);
      preparaApresentacaoCustos();
    })();

    function obtemIndexTipoCusto(coditoTipoCusto:number):number {
      if (UtilitarioGeral.validaLista(computedCustos.value)) {
        const index = computedCustos.value.findIndex((c) => c.codigoTipoCusto === coditoTipoCusto);
        if (index >= 0) { return index; }
      }

      return 0;
    }

    return {
      props,
      computedCustos,
      state,
      UtilitarioGeral,
      preparaApresentacaoCustos,
      obtemIndexTipoCusto,
    };
  },
});
