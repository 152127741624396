
import { computed, defineComponent, reactive } from 'vue';
import Card from '@/core/components/Tela/Card.vue';
import Icone from '@/core/components/Icone.vue';
import ProdutoCodigos from '@/components/Cadastros/Produtos/ProdutoCodigos.vue';
import ProdutoUnidades from '@/components/Cadastros/Produtos/ProdutoUnidades.vue';
import ProdutoFornecedores from '@/components/Cadastros/Produtos/ProdutoFornecedores.vue';
import ProdutoPesoMedidas from '@/components/Cadastros/Produtos/ProdutoPesoMedidas.vue';
import ProdutoImagens from '@/components/Cadastros/Produtos/ProdutoImagens.vue';
import ProdutoEstoque from '@/components/Cadastros/Produtos/ProdutoEstoque.vue';
import ProdutoCustos from '@/components/Cadastros/Produtos/ProdutoCustos.vue';
import ProdutoPrecos from '@/components/Cadastros/Produtos/ProdutoPrecos.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IProdutoCaracteristica, IProdutoDefinicao } from '@/models/Entidades/Cadastros/Produtos/IProduto';
import { ICaracteristica } from '@/models/Entidades/Cadastros/Produtos/ICaracteristica';
import { ETipoCaracteristica } from '@/models/Enumeradores/Cadastros/Produtos/ETipoCaracteristica';
import { useTelaBase } from '@/core/composables/TelaBase';

export default defineComponent({
  name: 'ProdutoEditarVariacao',
  props: {
    descricaoProduto: {
      type: String,
      required: true,
    },
    codigoUnidadePrincipal: {
      type: Number,
      required: true,
    },
    fatorUnidadePrincipal: {
      type: Number,
      required: true,
    },
    definicoesProdutos: {
      type: Array as () => IProdutoDefinicao[],
      required: true,
    },
    caracteristicasCadastradas: {
      type: Array as () => ICaracteristica[],
      required: true,
    },
    empresas: {
      type: Array as () => number[],
      required: true,
    },
  },
  components: {
    Card,
    Icone,
    ProdutoCodigos,
    ProdutoUnidades,
    ProdutoFornecedores,
    ProdutoPesoMedidas,
    ProdutoImagens,
    ProdutoEstoque,
    ProdutoCustos,
    ProdutoPrecos,
  },
  emits: ['update:definicoesProdutos', 'update:fatorUnidadePrincipal'],
  setup(props, { emit }) {
    const { apresentarMensagemAlerta } = useTelaBase();

    const computedFatorUnidadePrincipal = computed({
      get: () => props.fatorUnidadePrincipal,
      set: (valor: number) => {
        emit('update:fatorUnidadePrincipal', valor);
      },
    });

    const state = reactive({
      apresentarModalVariacao: false,
      produtoDefinicao: {} as IProdutoDefinicao,
      indexDefinicao: 0,
      preparandoCaracteristicas: false,
    });

    function editarVariacao(produtoDefinicao: IProdutoDefinicao, indexDefinicao: number) {
      state.apresentarModalVariacao = false;

      if (UtilitarioGeral.validaLista(props.caracteristicasCadastradas)) {
        state.produtoDefinicao = UtilitarioGeral.instanciaObjetoLocal(produtoDefinicao);
        state.indexDefinicao = indexDefinicao;
        state.apresentarModalVariacao = true;
      } else {
        apresentarMensagemAlerta('Nenhuma característica foi encontrada!');
      }
    }

    function confirmarDadosVariacao() {
      const definicoesProdutos = UtilitarioGeral.instanciaObjetoLocal(props.definicoesProdutos);
      definicoesProdutos[state.indexDefinicao] = state.produtoDefinicao;
      emit('update:definicoesProdutos', definicoesProdutos);
      state.apresentarModalVariacao = false;
    }

    function obtemDescricaoCaracteristica(codigoCaracteristica:number):string {
      const dadosCaracteristica = props.caracteristicasCadastradas.find((c) => c.codigo === codigoCaracteristica);
      if (dadosCaracteristica !== undefined) {
        return dadosCaracteristica.descricao;
      }
      return '';
    }

    function obtemValorCaracteristica(produtoCaracteristica: IProdutoCaracteristica):string {
      const dadosCaracteristica = props.caracteristicasCadastradas.find((c) => c.codigo === produtoCaracteristica.codigoCaracteristica);
      if (dadosCaracteristica !== undefined) {
        if (dadosCaracteristica.tipo === ETipoCaracteristica.ListaSimples || dadosCaracteristica.tipo === ETipoCaracteristica.Cor) {
          const itemCaracteristica = dadosCaracteristica.itens.find((c) => c.codigo === produtoCaracteristica.codigoCaracteristicaItem);
          if (itemCaracteristica !== undefined) {
            return itemCaracteristica.valor;
          }
        } else if (dadosCaracteristica.tipo === ETipoCaracteristica.ListaSubItens) {
          const itemCaracteristica = dadosCaracteristica.itens.find((c) => c.codigo === produtoCaracteristica.codigoCaracteristicaItem);
          if (itemCaracteristica !== undefined) {
            const subItemCaracteristica = itemCaracteristica.itens.find((c) => c.codigo === produtoCaracteristica.codigoCaracteristicaItem);
            if (subItemCaracteristica !== undefined) {
              return subItemCaracteristica.valor;
            }
          }
        }
        return dadosCaracteristica.descricao;
      }
      return '';
    }

    return {
      props,
      computedFatorUnidadePrincipal,
      state,
      UtilitarioGeral,
      editarVariacao,
      confirmarDadosVariacao,
      ETipoCaracteristica,
      obtemDescricaoCaracteristica,
      obtemValorCaracteristica,
    };
  },
});
