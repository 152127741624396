
import {
  defineComponent, reactive, watch,
} from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { useGradeBase } from '@/core/composables/GradeBase';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import PreviewPdf from '@/core/components/Modal/PreviewPdf.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import storeSistema from '@/store/storeSistema';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { EStatusTituloFinanceiro } from '@/models/Enumeradores/Financeiro/EStatusTituloFinanceiro';
import { EStatusRetornoBancario } from '@/models/Enumeradores/Financeiro/EStatusRetornoBancario';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { ISalvamentoAutomatico } from '@/models/Entidades/MeuSistema/ISalvamentoAutomatico';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { IArquivoPdf } from '@/core/models/IArquivoPdf';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import ServicoParametros from '@/servicos/MeuSistema/ServicoParametros';
import { IDTOProduto } from '@/models/DTO/Cadastros/Produtos/IDTOProduto';
import ServicoImportacaoProdutos from '@/servicos/ImportacoesRegistros/ServicoImportacaoProdutos';
import SelecionarUnidade from '@/components/Cadastros/Produtos/Unidades/SelecionarUnidade.vue';
import SelecionarCategoria from '@/components/Cadastros/Produtos/SelecionarCategoria.vue';
import SelecionarNcm from '@/components/Cadastros/Produtos/SelecionarNcm.vue';
import SelecionarMarca from '@/components/Cadastros/Produtos/SelecionarMarca.vue';

export default defineComponent({
  name: 'ImportacaoRegistrosExcelModal',
  props: {
    visivel: {
      type: Boolean,
      default: false,
    },
    empresa: {
      type: Number,
      required: true,
    },
  },
  components: {
    Icone,
    Card,
    RequisicaoModal,
    PreviewPdf,
    SelecionarUnidade,
    SelecionarCategoria,
    SelecionarNcm,
    SelecionarMarca,
  },
  emits: ['update:visivel', 'confirmacao'],
  setup(props, { emit }) {
    const {
      telaBase, apresentarMensagemAlerta, apresentarMensagemSucesso, apresentarMensagemErro,
    } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);
    const { gradeBase } = useGradeBase();
    const servicoSistema = new ServicoSistema();
    const servicoImportacaoProdutos = new ServicoImportacaoProdutos();
    const servicoParametros = new ServicoParametros();
    servicoImportacaoProdutos.requisicaoSistema();
    servicoParametros.requisicaoSistema();

    const state = reactive({
      produtos: [] as IDTOProduto[],
      cacheAutomatico: {} as ISalvamentoAutomatico,
      cacheDisponivel: false,
      nomeArquivo: '',
      buscaRapida: '',
      enviandoArquivo: false,
      painelUpload: 1,
      arquivosPdf: [] as IArquivoPdf[],
      codigoMarca: 0,
      codigoUnidade: 0,
      codigoCategoria: 0,
      codigoNcm: 0,
      descricaoMarca: '',
      descricaoUnidade: '',
      descricaoCategoria: '',
      descricaoNcm: '',
    });

    function preencherColunas() {
      if (storeSistema.state.layoutMobile) {
        gradeBase.colunas = [
          {
            title: 'Index', dataIndex: 'index', key: 'Index', position: 1, visible: false,
          },
          {
            title: 'Produto', dataIndex: 'produto', key: 'Produto', position: 2, visible: true,
          },
        ];
      } else {
        gradeBase.colunas = [
          {
            title: 'Index', dataIndex: 'index', key: 'Index', position: 1, visible: false,
          },
          {
            title: 'Código Interno', dataIndex: 'codigoInterno', key: 'CodigoInterno', position: 2, visible: true, ellipsis: true, width: 70,
          },
          {
            title: 'Código de Barras', dataIndex: 'codigoBarras', key: 'CodigoBarras', position: 3, visible: true, ellipsis: true, width: 70,
          },
          {
            title: 'Tipo', dataIndex: 'descricaoTipo', key: 'DescricaoTipo', position: 4, visible: true, width: 100, ellipsis: true, align: 'center',
          },
          {
            title: 'NCM', dataIndex: 'ncm', key: 'Ncm', position: 5, visible: true, width: 150, ellipsis: true, align: 'left',
          },
          {
            title: 'Descrição', dataIndex: 'descricao', key: 'Descricao', position: 6, visible: true, ellipsis: true, width: 150,
          },
          {
            title: 'Unidade', dataIndex: 'unidade', key: 'Unidade', position: 7, visible: true, ordering: true, ellipsis: true, width: 70, align: 'left',
          },
          {
            title: 'Categoria', dataIndex: 'categoria', key: 'Categoria', position: 8, visible: true, width: 70, ellipsis: true, align: 'left',
          },
          {
            title: 'Marca', dataIndex: 'marca', key: 'Marca', position: 9, visible: true, width: 70, ellipsis: true, align: 'left',
          },
          {
            title: 'Custo R$', dataIndex: 'custo', key: 'Custo', position: 10, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 80,
          },
          {
            title: 'Preço R$', dataIndex: 'preco', key: 'Preco', position: 11, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 100,
          },
        ];
      }
    }

    async function limparTela() {
      state.produtos = [] as IDTOProduto[];
      gradeBase.codigosSelecionados = [] as number[];
      state.nomeArquivo = '';
      state.buscaRapida = '';
      state.enviandoArquivo = false;
      state.painelUpload = 1;
      state.codigoMarca = 0;
      state.codigoNcm = 0;
      state.codigoCategoria = 0;
      state.codigoUnidade = 0;
      state.descricaoMarca = '';
      state.descricaoNcm = '';
      state.descricaoCategoria = '';
      state.descricaoUnidade = '';
      preencherColunas();
    }

    function obterIdentificadorCache(): string {
      return `IMPORTACAO_PRODUTOS_EXCEL_${storeSistema.getters.codigoUsuarioAutenticado()}`;
    }

    function salvarCache() {
      const identificador = obterIdentificadorCache();
      state.cacheAutomatico.data = UtilitarioData.obterDataAtual();
      state.cacheAutomatico.codigoEmpresa = props.empresa;
      state.cacheAutomatico.codigoUsuario = storeSistema.getters.codigoUsuarioAutenticado();
      state.cacheAutomatico.identificador = telaBase.identificadorRecurso;
      state.cacheAutomatico.dados = JSON.stringify(state.produtos);
      servicoSistema.salvamentoAutomaticoLocalStorage(`${identificador}`, state.cacheAutomatico);
      state.cacheDisponivel = false;
    }

    async function recuperarCache() {
      if (UtilitarioGeral.valorValido(state.cacheAutomatico.dados)) {
        const cache = JSON.parse(state.cacheAutomatico.dados);
        if (UtilitarioGeral.objetoValido(cache)) {
          servicoSistema.removerSalvamentoAutomaticoLocalStorage(obterIdentificadorCache());
          state.produtos = cache;
          state.cacheDisponivel = false;
          state.painelUpload = 0;
          apresentarMensagemSucesso('Informações recuperadas com sucesso!');
        }
      }
    }

    async function verificarCache() {
      const identificador = obterIdentificadorCache();
      const salvamentoAutomaticoLocalStorage = await servicoSistema.obterSalvamentoAutomaticoLocalStorage(identificador);
      if (UtilitarioGeral.objetoValido(salvamentoAutomaticoLocalStorage)) {
        state.cacheAutomatico = salvamentoAutomaticoLocalStorage;
        if (UtilitarioData.validaDataPreenchida(state.cacheAutomatico.data)) {
          const cache = JSON.parse(state.cacheAutomatico.dados);
          state.cacheAutomatico = cache;
          state.cacheDisponivel = true;
        }
      }
    }

    function selecionarArquivoUpload() {
      const elemento = document.getElementById('selecionarArquivo');
      if (elemento !== null) {
        elemento.click();
      }
    }

    const mudarSelecao = (selectedRowKeys: any) => {
      gradeBase.codigosSelecionados = selectedRowKeys;
    };

    async function enviarArquivoLeitura(arquivo: any) {
      limparTela();
      state.enviandoArquivo = true;
      const retorno = await servicoImportacaoProdutos.lerArquivoProdutos(props.empresa, arquivo);
      state.nomeArquivo = arquivo.name;
      if (UtilitarioGeral.validaLista(retorno)) {
        if (!UtilitarioGeral.validaLista(retorno)) {
          apresentarMensagemAlerta('Não foi possível ler o arquivo selecionado!');
        } else {
          state.produtos = retorno;
          state.produtos.forEach((t, index) => {
            state.produtos[index].index = index;
            state.produtos[index].visivel = true;
          });
          state.painelUpload = 0;
        }
      } else {
        apresentarMensagemAlerta('Ocorreu um erro ao ler o arquivo');
      }
      state.enviandoArquivo = false;
    }

    async function uploadArquivoSelecionado(event: any) {
      await enviarArquivoLeitura(event.target.files[0]);
    }

    async function uploadArquivoArrastado(event: any) {
      await enviarArquivoLeitura(event.dataTransfer.files[0]);
    }

    function verificarFiltro() {
      state.produtos.forEach((p, index) => {
        if (state.buscaRapida === '' || state.buscaRapida === undefined) {
          state.produtos[index].visivel = true;
        } else if (UtilitarioGeral.valorValido(p.codigoInterno) && p.codigoInterno.toLowerCase().includes(state.buscaRapida)) {
          state.produtos[index].visivel = true;
        } else if (UtilitarioGeral.valorValido(p.marca) && p.marca.toLowerCase().includes(state.buscaRapida)) {
          state.produtos[index].visivel = true;
        } else if (UtilitarioGeral.valorValido(p.ncm) && p.ncm.toLowerCase().includes(state.buscaRapida)) {
          state.produtos[index].visivel = true;
        } else if (UtilitarioGeral.valorValido(p.unidade) && p.unidade.toLowerCase().includes(state.buscaRapida)) {
          state.produtos[index].visivel = true;
        } else if (UtilitarioGeral.valorValido(p.categoria) && p.categoria.toLowerCase().includes(state.buscaRapida)) {
          state.produtos[index].visivel = true;
        } else if (UtilitarioGeral.valorValido(p.descricao) && p.descricao.toLowerCase().includes(state.buscaRapida)) {
          state.produtos[index].visivel = true;
        } else {
          state.produtos[index].visivel = false;
        }
      });
    }

    function alterarMarcas() {
      state.produtos.forEach((p, index) => {
        if (gradeBase.codigosSelecionados.includes(index)) {
          state.produtos[index].codigoMarca = state.codigoMarca;
          state.produtos[index].marca = state.descricaoMarca;
        }
      });
    }

    function alterarCategorias() {
      state.produtos.forEach((p, index) => {
        if (gradeBase.codigosSelecionados.includes(index)) {
          state.produtos[index].codigoCategoria = state.codigoCategoria;
          state.produtos[index].categoria = state.descricaoCategoria;
        }
      });
    }

    function alterarUnidades() {
      state.produtos.forEach((p, index) => {
        if (gradeBase.codigosSelecionados.includes(index)) {
          state.produtos[index].codigoUnidade = state.codigoUnidade;
          state.produtos[index].unidade = state.descricaoUnidade;
        }
      });
    }

    function alterarNcms() {
      state.produtos.forEach((p, index) => {
        if (gradeBase.codigosSelecionados.includes(index)) {
          state.produtos[index].codigoNcm = state.codigoNcm;
          state.produtos[index].ncm = state.descricaoNcm;
        }
      });
    }

    function realizarAjustes() {
      if (gradeBase.codigosSelecionados.length === 0) {
        apresentarMensagemAlerta('nenhum produto selecionado!');
        return;
      }

      if (state.codigoMarca > 0) {
        alterarMarcas();
      }

      if (state.codigoCategoria > 0) {
        alterarCategorias();
      }

      if (state.codigoNcm > 0) {
        alterarNcms();
      }

      if (state.codigoUnidade > 0) {
        alterarUnidades();
      }

      state.codigoCategoria = 0;
      state.codigoMarca = 0;
      state.codigoNcm = 0;
      state.codigoUnidade = 0;
      gradeBase.codigosSelecionados = [] as number[];
    }

    function validarProdutos() {
      let retorno = true;

      state.produtos.forEach((p, index) => {
        if (gradeBase.codigosSelecionados.includes(index)) {
          if (!UtilitarioGeral.valorValido(p.codigoInterno) && retorno) {
            apresentarMensagemAlerta('Um dos produtos está com o código interno inválido!');
            retorno = false;
          }

          if (!UtilitarioGeral.valorValido(p.descricao) && retorno) {
            apresentarMensagemAlerta('Um dos produtos está com a descrição inválida!');
            retorno = false;
          }

          if (!UtilitarioGeral.validaCodigo(p.codigoCategoria) && retorno) {
            apresentarMensagemAlerta('Um dos produtos está com a categoria inválida!');
            retorno = false;
          }

          if (!UtilitarioGeral.validaCodigo(p.codigoUnidade) && retorno) {
            apresentarMensagemAlerta('Um dos produtos está com a unidade inválida!');
            retorno = false;
          }

          if (!UtilitarioGeral.validaCodigo(p.codigoNcm) && retorno) {
            apresentarMensagemAlerta('Um dos produtos está o NCM inválido!');
            retorno = false;
          }
        }
      });
      return retorno;
    }

    async function baixarArquivo() {
      const retorno = await servicoImportacaoProdutos.obterArquivoPadraoProdutos();
      if (UtilitarioGeral.valorValido(retorno)) {
        UtilitarioGeral.downloadArquivo(retorno);
      } else {
        apresentarMensagemErro('Ocorreu um erro ao obter a planilha de exemplo!');
      }
    }

    async function importarProdutos() {
      if (gradeBase.codigosSelecionados.length === 0) {
        apresentarMensagemAlerta('nenhum produto selecionado!');
        return;
      }

      if (!validarProdutos()) {
        return;
      }

      const produtosSelecionados = [] as IDTOProduto[];
      state.produtos.forEach((p, index) => {
        if (gradeBase.codigosSelecionados.includes(index)) {
          produtosSelecionados.push(p);
        }
      });

      servicoImportacaoProdutos.importarProdutos(props.empresa, produtosSelecionados);
      modalBase.retornoRequisicao = {} as IRetornoRequisicao;
      modalBase.retornoRequisicao.mensagem = 'Os produtos serão importados em segundo plano assim que a importação for finalizada você será notificado!';
      modalBase.exibirRequisicaoModal = true;
      emit('confirmacao');
      modalBase.computedVisivel = false;
    }

    watch(async () => modalBase.computedVisivel, async () => {
      limparTela();
      telaBase.carregando = true;
      if (modalBase.computedVisivel) {
        verificarCache();
      }
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      emit,
      telaBase,
      modalBase,
      gradeBase,
      storeSistema,
      window,
      EStatusRetornoBancario,
      EStatusTituloFinanceiro,
      UtilitarioData,
      UtilitarioMascara,
      UtilitarioGeral,
      mudarSelecao,
      selecionarArquivoUpload,
      uploadArquivoSelecionado,
      salvarCache,
      uploadArquivoArrastado,
      recuperarCache,
      verificarFiltro,
      importarProdutos,
      baixarArquivo,
      realizarAjustes,
      ECustomRenderRow,
    };
  },
});
