
import {
  computed, defineComponent, onBeforeMount, reactive,
} from 'vue';
import Card from '@/core/components/Tela/Card.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import SelecionarTipoCusto from '@/components/Cadastros/Precificacao/SelecionarTipoCusto.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { ITabelaPrecoItemProduto } from '@/models/Entidades/Cadastros/Precificacao/ITabelaPreco';
import { IDTOTabelaPreco, IDTOTabelaPrecoEstrutura } from '@/models/DTO/Cadastros/Precificacao/IDTOTabelaPreco';
import ServicoTabelaPreco from '@/servicos/Cadastros/Precificacao/ServicoTabelaPreco';
import { ETipoItem } from '@/models/Enumeradores/Compartilhados/ETipoItem';
import { EFormaInclusaoItens } from '@/models/Enumeradores/Cadastros/Precificacao/EFormaInclusaoItens';
import { ITipoPreco } from '@/models/Entidades/Cadastros/Precificacao/ITipoPreco';
import { IProdutoCusto } from '@/models/Entidades/Cadastros/Produtos/IProduto';
import UtilitarioMatematico from '@/core/utilitarios/UtilitarioMatematico';

export default defineComponent({
  name: 'ProdutoPrecos',
  props: {
    precos: {
      type: Array as () => ITabelaPrecoItemProduto[],
      required: true,
    },
    custos: {
      type: Array as () => IProdutoCusto[],
      required: true,
    },
    codigoProdutoDefinicao: {
      type: Number,
      required: true,
    },
    empresas: {
      type: Array as () => number[],
      required: true,
    },
  },
  components: {
    CampoNumerico,
    Card,
    MensagemSemDados,
    SelecionarTipoCusto,
  },
  emits: ['update:precos'],
  setup(props, { emit }) {
    const computedPrecos = computed({
      get: () => props.precos,
      set: (valor: ITabelaPrecoItemProduto[]) => {
        emit('update:precos', valor);
      },
    });

    const state = reactive({
      tabelasPrecosApi: [] as IDTOTabelaPreco[],
      tabelasPrecosApresentacao: [] as IDTOTabelaPreco[],
      tiposPrecos: [] as ITipoPreco[],
      preparandoPrecos: false,
    });

    state.tabelasPrecosApi = [];
    state.tabelasPrecosApresentacao = [];
    state.tiposPrecos = [];

    function criaPrecoProdutoDefinicao(estrutura: IDTOTabelaPrecoEstrutura) {
      const precoProduto: ITabelaPrecoItemProduto = {} as ITabelaPrecoItemProduto;
      precoProduto.codigo = 0;
      precoProduto.codigoProdutoDefinicao = props.codigoProdutoDefinicao;
      precoProduto.codigoTabelaPreco = estrutura.codigoTabelaPreco;
      precoProduto.codigoTipoCusto = estrutura.codigoTipoCusto;
      precoProduto.codigoTipoPreco = estrutura.codigoTipoPreco;
      precoProduto.valor = 0;
      precoProduto.markup = estrutura.markupPadrao;
      precoProduto.descontoMaximo = estrutura.descontoMaximoPadrao;
      precoProduto.dataAlteracao = '';

      return precoProduto;
    }

    function preparaApresentacaoPrecos() {
      let precosProduto: ITabelaPrecoItemProduto[] = [];

      state.preparandoPrecos = true;

      if (UtilitarioGeral.validaLista(computedPrecos.value)) {
        precosProduto = UtilitarioGeral.instanciaObjetoLocal(computedPrecos.value);
      }
      let criouItemPreco = false;

      if (UtilitarioGeral.validaLista(state.tabelasPrecosApi)) {
        state.tabelasPrecosApi.forEach((tabelaPreco) => {
          // Adiciona apenas tabelas de preços que tem inclusão novos itens de forma automática
          if (tabelaPreco.formaInclusaoItens === EFormaInclusaoItens.Automatica) {
            state.tabelasPrecosApresentacao.push(tabelaPreco);

            if (UtilitarioGeral.validaLista(tabelaPreco.estruturas)) {
              tabelaPreco.estruturas.forEach((estrutura) => {
                if (UtilitarioGeral.validaLista(precosProduto)) {
                  if (!precosProduto.some((c) => c.codigoProdutoDefinicao === props.codigoProdutoDefinicao
                    && c.codigoTabelaPreco === estrutura.codigoTabelaPreco
                    && c.codigoTipoPreco === estrutura.codigoTipoPreco)) {
                    precosProduto.push(criaPrecoProdutoDefinicao(estrutura));
                    criouItemPreco = true;
                  }
                } else {
                  precosProduto.push(criaPrecoProdutoDefinicao(estrutura));
                  criouItemPreco = true;
                }
              });
            }
          }
        });

        if (UtilitarioGeral.validaLista(precosProduto)) {
          const codigosTabelasItens: number[] = [];
          precosProduto.forEach((precoProduto) => {
            if (!codigosTabelasItens.includes(precoProduto.codigoTabelaPreco)) {
              codigosTabelasItens.push(precoProduto.codigoTabelaPreco);
            }
          });
          // Verificação para obter tabelas de preços que podem não ser automáticas
          // ou já adicionadas para o produto que não foi adicionado anteriormente
          codigosTabelasItens.forEach((codigoTabela) => {
            const tabelaPreco = state.tabelasPrecosApresentacao.find((c) => c.codigo === codigoTabela);
            if (tabelaPreco === undefined) {
              const tabelaPrecoApi = state.tabelasPrecosApi.find((c) => c.codigo === codigoTabela);
              if (tabelaPrecoApi !== undefined) {
                state.tabelasPrecosApresentacao.push(tabelaPrecoApi);
              }
            }
          });
        }

        if (state.tabelasPrecosApresentacao.length > 1) {
          const tiposPrecos: ITipoPreco[] = [];
          state.tabelasPrecosApresentacao.forEach((tabelaPreco) => {
            tabelaPreco.estruturas.forEach((estrutura) => {
              if (!tiposPrecos.some((c) => c.codigo === estrutura.codigoTipoPreco)) {
                const tipoPreco: ITipoPreco = {} as ITipoPreco;
                tipoPreco.codigo = estrutura.codigoTipoPreco;
                tipoPreco.descricao = estrutura.descricaoTipoPreco;
                tipoPreco.casasDecimais = estrutura.casasDecimaisTipoPreco;
                tiposPrecos.push(tipoPreco);
              }
            });
          });

          state.tiposPrecos = tiposPrecos;
        }
      }
      if (criouItemPreco) { computedPrecos.value = precosProduto; }
      state.preparandoPrecos = false;
    }

    onBeforeMount(async () => {
      state.tabelasPrecosApresentacao = [];
      state.tabelasPrecosApi = await new ServicoTabelaPreco().obterTabelasPrecoEstruturaCompleta(props.empresas, ETipoItem.Produto, true);
      preparaApresentacaoPrecos();
    });

    function obtemIndexPrecoTabela(codigoTabela: number, codigoTipoPreco: number): number {
      if (UtilitarioGeral.validaLista(computedPrecos.value)) {
        const index = computedPrecos.value.findIndex((c) => c.codigoTabelaPreco === codigoTabela && c.codigoTipoPreco === codigoTipoPreco);
        if (index >= 0) { return index; }
      }

      return 0;
    }

    function verificarTipoPrecoEstrutura(tipoPreco: number, tabelaPreco: IDTOTabelaPreco) {
      const index = tabelaPreco.estruturas.findIndex((e) => e.codigoTipoPreco === tipoPreco);
      return index > -1;
    }

    function atualizarPrecoMarkup(indexTipoPreco: number, markup: boolean) {
      const indexCusto = props.custos.findIndex((c) => c.codigoTipoCusto === computedPrecos.value[indexTipoPreco].codigoTipoCusto);
      const valorCusto = props.custos[indexCusto].valor;
      if (markup) {
        computedPrecos.value[indexTipoPreco].valor = valorCusto + UtilitarioMatematico.calcularValorPercentual(valorCusto, computedPrecos.value[indexTipoPreco].markup);
      } else if (valorCusto > 0 && computedPrecos.value[indexTipoPreco].valor) {
        const valorDiferenca = computedPrecos.value[indexTipoPreco].valor - valorCusto;
        const valroCalculado = UtilitarioMatematico.calcularPorcentagem(valorCusto, valorDiferenca);
        computedPrecos.value[indexTipoPreco].markup = valroCalculado;
      }
    }

    return {
      props,
      computedPrecos,
      state,
      preparaApresentacaoPrecos,
      obtemIndexPrecoTabela,
      verificarTipoPrecoEstrutura,
      atualizarPrecoMarkup,
    };
  },
});
