
import { computed, defineComponent, reactive } from 'vue';
import Icone from '@/core/components/Icone.vue';
import SelecionarSimNao from '@/core/components/Tela/SelecionarSimNao.vue';
import SelecionarOrigemMercadoria from '../Tributacoes/CenariosFiscais/SelecionarOrigemMercadoria.vue';
import SelecionarTipoProduto from './SelecionarTipoProduto.vue';
import SelecionarUnidade from './Unidades/SelecionarUnidade.vue';
import SelecionarCategoria from './SelecionarCategoria.vue';
import SelecionarMarca from './SelecionarMarca.vue';
import SelecionarNcm from './SelecionarNcm.vue';
import SelecionarCest from './SelecionarCest.vue';
import SelecionarGenero from './SelecionarGenero.vue';
import { IProduto } from '@/models/Entidades/Cadastros/Produtos/IProduto';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';

export default defineComponent({
  name: 'ProdutoDadosPrincipais',
  props: {
    empresas: {
      type: Array as () => number[],
      required: true,
    },
    produto: {
      type: Object as () => IProduto,
      required: true,
    },
    categoriasSelecionadas: {
      type: Array as () => number[],
      default: [] as number[],
      required: true,
    },
  },
  components: {
    Icone,
    SelecionarSimNao,
    SelecionarOrigemMercadoria,
    SelecionarTipoProduto,
    SelecionarUnidade,
    SelecionarCategoria,
    SelecionarMarca,
    SelecionarNcm,
    SelecionarCest,
    SelecionarGenero,
  },
  emits: ['update:produto', 'update:categoriasSelecionadas', 'alterarVariacao'],
  setup(props, { emit }) {
    const state = reactive({
      ncmSelecionado: '',
    });

    const computedProduto = computed({
      get: () => props.produto,
      set: (produto: IProduto) => {
        emit('update:produto', produto);
      },
    });

    const computedCategoriasSelecionadas = computed({
      get: () => props.categoriasSelecionadas,
      set: (categorias: number[]) => {
        emit('update:categoriasSelecionadas', categorias);
      },
    });

    function mudarComportamentoVariacao() {
      emit('alterarVariacao');
    }

    function atualizarGenero() {
      if (UtilitarioGeral.valorValido(state.ncmSelecionado)) {
        computedProduto.value.genero = state.ncmSelecionado.substring(0, 2);
      }
    }

    return {
      props,
      state,
      computedProduto,
      computedCategoriasSelecionadas,
      mudarComportamentoVariacao,
      atualizarGenero,
    };
  },
});
