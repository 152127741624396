
import {
  computed, defineComponent, reactive, ref,
} from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import SelecionarUnidade from '@/components/Cadastros/Produtos/Unidades/SelecionarUnidade.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IProdutoCodigo, IProdutoUnidade } from '@/models/Entidades/Cadastros/Produtos/IProduto';
import { IOptionString } from '@/core/models/AntDesign/IOption';

export default defineComponent({
  name: 'ProdutoUnidades',
  props: {
    empresas: {
      type: Array as () => number[],
      required: true,
    },
    codigoUnidadePrincipal: {
      type: Number,
      required: true,
    },
    fatorUnidadePrincipal: {
      type: Number,
      default: 1,
    },
    unidades: {
      type: Array as () => IProdutoUnidade[],
      required: true,
    },
    codigos: {
      type: Array as () => IProdutoCodigo[],
      required: true,
    },
  },
  components: {
    Icone,
    Card,
    CampoNumerico,
    MensagemSemDados,
    SelecionarUnidade,
  },
  emits: ['update:fatorUnidadePrincipal', 'update:unidades'],
  setup(props, { emit }) {
    const { apresentarMensagemAlerta } = useTelaBase();
    const campoNovaUnidade = ref<HTMLElement | null>(null);
    const campoFatorNovaUnidade = ref<HTMLElement | null>(null);

    const state = reactive({
      novaUnidade: {} as IProdutoUnidade,
    });
    state.novaUnidade.percentualFormacaoPreco = 0;

    const computedFatorUnidadePrincipal = computed({
      get: () => props.fatorUnidadePrincipal,
      set: (valor: number) => {
        emit('update:fatorUnidadePrincipal', valor);
      },
    });

    function montaCodigosExibicao():IOptionString[] {
      const codigos:IOptionString[] = [];
      if (UtilitarioGeral.validaLista(props.codigos)) {
        props.codigos.forEach((codigo) => {
          const opcaoCodigo:IOptionString = {} as IOptionString;
          opcaoCodigo.value = codigo.codigoProduto;
          opcaoCodigo.label = codigo.codigoProduto;
          codigos.push(opcaoCodigo);
        });
      }
      return codigos;
    }
    const computedCodigoProdutos = computed(() => montaCodigosExibicao());

    const computedUnidades = computed({
      get: () => props.unidades,
      set: (valor: IProdutoUnidade[]) => {
        emit('update:unidades', valor);
      },
    });

    function calculaFatorConversao(fatorUnidadePrincipal:number, fatorUnidadeSecundaria:number):number {
      let fatorCalculado = 0;
      if (fatorUnidadePrincipal > 0 && fatorUnidadeSecundaria > 0) {
        fatorCalculado = (fatorUnidadePrincipal / fatorUnidadeSecundaria);
      }

      return fatorCalculado;
    }

    function verificaAlteracaoFatorUnidadePrincipal(fatorConversaoPrincipal:number) {
      if (UtilitarioGeral.validaLista(computedUnidades.value)) {
        for (let i = 0; i < computedUnidades.value.length; (i += 1)) {
          if (fatorConversaoPrincipal > 0 && computedUnidades.value[i].fator > 0) {
            computedUnidades.value[i].fatorConversao = calculaFatorConversao(fatorConversaoPrincipal, computedUnidades.value[i].fator);
          }
        }
      }
    }

    function verificaFatorUnidadePrincipal() {
      const fatorConversaoPadrao = 1;
      if (!UtilitarioGeral.valorValido(props.fatorUnidadePrincipal)) {
        computedFatorUnidadePrincipal.value = fatorConversaoPadrao;
        verificaAlteracaoFatorUnidadePrincipal(fatorConversaoPadrao);
      } else if (!(props.fatorUnidadePrincipal > 0)) {
        computedFatorUnidadePrincipal.value = fatorConversaoPadrao;
        verificaAlteracaoFatorUnidadePrincipal(fatorConversaoPadrao);
      }
    }

    function removerUnidade(index: number) {
      computedUnidades.value.splice(index, 1);
    }
    function focoNovaUnidade() {
      if (campoNovaUnidade.value) {
        campoNovaUnidade.value.focus();
      }
    }
    function focoFatorNovaUnidade() {
      if (campoFatorNovaUnidade.value) {
        campoFatorNovaUnidade.value.focus();
      }
    }

    function adicionarUnidade() {
      if (!UtilitarioGeral.valorValido(state.novaUnidade.codigoUnidade)) {
        apresentarMensagemAlerta('Por favor, selecione uma unidade válida!');
        focoNovaUnidade();
        return;
      }

      if (UtilitarioGeral.validaLista(computedUnidades.value)) {
        if (computedUnidades.value.some((c) => c.codigoUnidade === state.novaUnidade.codigoUnidade)) {
          apresentarMensagemAlerta('A unidade selecionada já foi adicionada!');
          focoNovaUnidade();
          return;
        }
      }

      if (!UtilitarioGeral.valorValido(state.novaUnidade.fator)) {
        apresentarMensagemAlerta('Por favor, digite o fator da unidade!');
        focoFatorNovaUnidade();
        return;
      }

      if (state.novaUnidade.fator <= 0) {
        apresentarMensagemAlerta('Por favor, O fator da unidade é inválido!');
        focoFatorNovaUnidade();
        return;
      }

      const unidadeProduto:IProdutoUnidade = {} as IProdutoUnidade;
      unidadeProduto.codigo = 0;
      unidadeProduto.codigoProdutoDefinicao = 0;
      unidadeProduto.codigoProdutoCodigo = 0;
      unidadeProduto.codigoProduto = state.novaUnidade.codigoProduto;
      unidadeProduto.codigoUnidade = state.novaUnidade.codigoUnidade;
      unidadeProduto.fator = state.novaUnidade.fator;
      unidadeProduto.fatorConversao = calculaFatorConversao(computedFatorUnidadePrincipal.value, state.novaUnidade.fator);
      unidadeProduto.percentualFormacaoPreco = state.novaUnidade.percentualFormacaoPreco;
      computedUnidades.value.push(unidadeProduto);
      state.novaUnidade = {} as IProdutoUnidade;
      state.novaUnidade.percentualFormacaoPreco = 0;
      focoNovaUnidade();
    }

    function obterCodigosUnidadesParaOcultar():number [] {
      const codigosUnidades:number[] = [];
      if (UtilitarioGeral.valorValido(props.codigoUnidadePrincipal)) {
        codigosUnidades.push(props.codigoUnidadePrincipal);
      }
      if (UtilitarioGeral.validaLista(computedUnidades.value)) {
        computedUnidades.value.forEach((unidadeProduto) => {
          codigosUnidades.push(unidadeProduto.codigoUnidade);
        });
      }

      return codigosUnidades;
    }
    return {
      props,
      computedFatorUnidadePrincipal,
      computedCodigoProdutos,
      computedUnidades,
      state,
      campoNovaUnidade,
      campoFatorNovaUnidade,
      focoFatorNovaUnidade,
      adicionarUnidade,
      removerUnidade,
      obterCodigosUnidadesParaOcultar,
      verificaAlteracaoFatorUnidadePrincipal,
      verificaFatorUnidadePrincipal,
    };
  },
});
