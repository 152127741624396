
import {
  computed, defineComponent, reactive, ref,
} from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IProdutoCodigo } from '@/models/Entidades/Cadastros/Produtos/IProduto';
import { ETipoCodigo } from '@/models/Enumeradores/Cadastros/Produtos/ETipoCodigo';

export default defineComponent({
  name: 'ProdutoCodigos',
  props: {
    codigoInterno: {
      type: String,
      required: true,
    },
    codigos: {
      type: Array as () => IProdutoCodigo[],
      required: true,
    },
  },
  components: {
    Icone,
    Card,
    MensagemSemDados,
  },
  emits: ['update:codigoInterno', 'update:codigos'],
  setup(props, { emit }) {
    const { apresentarMensagemAlerta } = useTelaBase();
    const campoNovoCodigo = ref<HTMLElement | null>(null);

    const state = reactive({
      apresentarCodigosModal: false,
      tipoSelecionado: {} as ETipoCodigo,
      codigosModal: [] as IProdutoCodigo[],
      novoCodigo: '',
    });
    state.codigosModal = [];

    const computedCodigoInterno = computed({
      get: () => props.codigoInterno,
      set: (valor: string) => {
        emit('update:codigoInterno', valor);
      },
    });

    const computedCodigos = computed({
      get: () => props.codigos,
      set: (valor: IProdutoCodigo[]) => {
        emit('update:codigos', valor);
      },
    });

    function obterDescricaoTipoCodigo(tipoCodigo: number): string {
      if (tipoCodigo === ETipoCodigo.CodigoBarras) {
        return 'Códigos de Barras';
      } if (tipoCodigo === ETipoCodigo.Personalizado) {
        return 'Códigos Personalizados';
      } if (tipoCodigo === ETipoCodigo.CodigoBalanca) {
        return 'Códigos de Balança';
      }

      return '';
    }

    function ocultarGerenciadorCodigos() {
      state.codigosModal = [];
      state.tipoSelecionado = 0;
      state.novoCodigo = '';
      state.apresentarCodigosModal = false;
    }

    function obterCodigoPrincipal(tipoCodigo: number): string {
      if (UtilitarioGeral.validaLista(computedCodigos.value)) {
        const codigoProduto = computedCodigos.value.find((c) => c.tipoCodigo === tipoCodigo && c.principal === true);
        if (codigoProduto !== undefined) {
          return codigoProduto.codigoProduto;
        }
      }

      return '';
    }
    async function confirmarCodigos(tipoCodigo:ETipoCodigo) {
      const listaCodigos:IProdutoCodigo[] = [];
      if (UtilitarioGeral.validaLista(computedCodigos.value)) {
        const codigosFiltrados = computedCodigos.value.filter((c) => c.tipoCodigo !== tipoCodigo);
        if (UtilitarioGeral.validaLista(codigosFiltrados)) {
          codigosFiltrados.forEach((codigoProduto) => {
            listaCodigos.push(codigoProduto);
          });
        }
      }

      if (UtilitarioGeral.validaLista(state.codigosModal)) {
        state.codigosModal.forEach((codigoProduto) => {
          listaCodigos.push(codigoProduto);
        });
      }
      ocultarGerenciadorCodigos();
      computedCodigos.value = listaCodigos;
    }

    function gerenciarCodigos(tipoCodigo:ETipoCodigo) {
      state.codigosModal = [];
      state.novoCodigo = '';
      state.tipoSelecionado = tipoCodigo;
      if (UtilitarioGeral.validaLista(computedCodigos.value)) {
        let codigosFiltrados = computedCodigos.value.filter((c) => c.tipoCodigo === tipoCodigo);
        if (UtilitarioGeral.validaLista(codigosFiltrados)) {
          codigosFiltrados = UtilitarioGeral.instanciaObjetoLocal(codigosFiltrados);
          codigosFiltrados.forEach((codigoProduto) => {
            state.codigosModal.push(codigoProduto);
          });
        }
      }

      state.apresentarCodigosModal = true;
    }

    function removerCodigo(index: number) {
      state.codigosModal.splice(index, 1);
    }
    function focoNovoCodigo() {
      if (campoNovoCodigo.value) {
        campoNovoCodigo.value.focus();
      }
    }

    function adicionarCodigo() {
      if (!UtilitarioGeral.valorValido(state.novoCodigo)) {
        apresentarMensagemAlerta('Por favor, digite um código!');
        focoNovoCodigo();
        return;
      }

      if (UtilitarioGeral.validaLista(state.codigosModal)) {
        if (state.codigosModal.some((c) => c.codigoProduto === state.novoCodigo)) {
          apresentarMensagemAlerta('O código digitado já foi adicionado!');
          focoNovoCodigo();
          return;
        }
      }

      const codigoProduto:IProdutoCodigo = {} as IProdutoCodigo;
      codigoProduto.codigo = 0;
      codigoProduto.codigoProdutoDefinicao = 0;
      codigoProduto.codigoProduto = state.novoCodigo;
      codigoProduto.tipoCodigo = state.tipoSelecionado;
      codigoProduto.principal = false;
      state.codigosModal.push(codigoProduto);
      state.novoCodigo = '';
      focoNovoCodigo();
    }

    function defineApenasUmPrincipal(index:number, valor: boolean) {
      if (valor) {
        if (UtilitarioGeral.validaLista(state.codigosModal)) {
          for (let i = 0; i < state.codigosModal.length; (i += 1)) {
            if (i !== index) {
              state.codigosModal[i].principal = false;
            }
          }
        }
      }
    }
    return {
      props,
      ETipoCodigo,
      computedCodigoInterno,
      computedCodigos,
      state,
      campoNovoCodigo,
      gerenciarCodigos,
      confirmarCodigos,
      adicionarCodigo,
      defineApenasUmPrincipal,
      ocultarGerenciadorCodigos,
      removerCodigo,
      obterDescricaoTipoCodigo,
      obterCodigoPrincipal,
    };
  },
});
