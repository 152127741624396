import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IServicoBase } from '@/core/models/IServicoBase';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { ICaracteristica } from '@/models/Entidades/Cadastros/Produtos/ICaracteristica';
import { IDTOCaracteristica } from '@/models/DTO/Cadastros/Produtos/IDTOCaracteristica';
import { IItemGenerico } from '@/core/models/IItemGenerico';

/**
 * Serviço de Características
 * Fornece todas regras de negócios e lógicas relacionado a características.
 */

class ServicoCaracteristica implements IServicoBase<ICaracteristica> {
    endPoint = 'caracteristicas';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async obterListaTipoCaracteristica(): Promise<IItemGenerico[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/tipos`);
      return result.data;
    }

    public async obterTipoCaracteristica(codigoCaracteristica:number): Promise<number> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigoCaracteristica}/tipo`);
      return result.data;
    }

    public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
      const listaPropriedades = await this.apiERP.obterPropriedadesConsulta(this.endPoint);
      return listaPropriedades;
    }

    public async buscaAvancada(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
      return this.apiERP.buscaAvancada(this.endPoint, parametrosConsulta, filtros);
    }

    public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
      return this.apiERP.relatorioPadrao(this.endPoint, tipoArquivo, parametrosConsulta, filtros, codigosSelecionados);
    }

    public obterIdentificacaoRegistroGradeCRUD(objeto: IDTOCaracteristica): string {
      return objeto.descricao;
    }

    public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida): Promise<IItemConsultaRapida[]> {
      const parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
      const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}`);
      return result.data;
    }

    public async consultaRapidaItens(codigoCaracteristica:number, parametrosConsultaRapida: IParametrosConsultaRapida): Promise<IItemConsultaRapida[]> {
      const parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigoCaracteristica}/itens/consulta-rapida${parametrosAdicionais}`);
      return result.data;
    }

    public async consultaRapidaSubItens(codigoCaracteristica:number, parametrosConsultaRapida: IParametrosConsultaRapida): Promise<IItemConsultaRapida[]> {
      const parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigoCaracteristica}/sub-itens/consulta-rapida${parametrosAdicionais}`);
      return result.data;
    }

    public async incluir(caracteristica: ICaracteristica): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}`, caracteristica);
      return result.data;
    }

    public async alterar(caracteristica: ICaracteristica): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/${caracteristica.codigo}`, caracteristica);
      return result.data;
    }

    public async obter(codigo: number, empresa: number): Promise<ICaracteristica> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async excluir(codigo: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}`);
      return result.data;
    }

    public async obterTodasCaracteristicas(): Promise<ICaracteristica[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/todas`);
      return result.data;
    }
}
export default ServicoCaracteristica;
