import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "ss-tamanho-completo" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "ss-tamanho-completo ss-alinhar-centro" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "ant-row" }
const _hoisted_6 = { class: "ant-col ant-col-xs-24 ant-col-xl-16" }
const _hoisted_7 = { class: "ant-col ant-col-xs-24 ant-col-xl-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_card = _resolveComponent("card")!
  const _component_mensagem_sem_dados = _resolveComponent("mensagem-sem-dados")!
  const _component_campo_numerico = _resolveComponent("campo-numerico")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_selecionar_data = _resolveComponent("selecionar-data")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.state.preparandoCustos)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_card, { titulo: " " }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createTextVNode(" Carregando custos "),
                _createVNode(_component_a_spin)
              ])
            ]),
            _: 1
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_a_form, { layout: "vertical" }, {
            default: _withCtx(() => [
              (_ctx.state.tipoCustosApresentacao.length <= 0)
                ? (_openBlock(), _createBlock(_component_mensagem_sem_dados, {
                    key: 0,
                    mensagem: "Nenhum tipo de custo foi cadastrado ainda"
                  }))
                : (_ctx.computedCustos.length > 0)
                  ? (_openBlock(), _createBlock(_component_card, {
                      key: 1,
                      titulo: "CUSTOS"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_5, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.tipoCustosApresentacao, (tipoCusto, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: index,
                              class: "ant-col ant-col-xs-24 ant-col-xl-8"
                            }, [
                              _createElementVNode("div", {
                                class: _normalizeClass((index % 3)?'ant-row ss-separador':'ant-row')
                              }, [
                                _createElementVNode("div", _hoisted_6, [
                                  _createVNode(_component_a_form_item, {
                                    label: _ctx.UtilitarioGeral.primeiraLetraMaiuscula(tipoCusto.descricao),
                                    class: "ss-margin-campos"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_campo_numerico, {
                                        valor: _ctx.computedCustos[_ctx.obtemIndexTipoCusto(tipoCusto.codigo)].valor,
                                        "onUpdate:valor": ($event: any) => ((_ctx.computedCustos[_ctx.obtemIndexTipoCusto(tipoCusto.codigo)].valor) = $event),
                                        monetario: true,
                                        quantidadeCasasDecimais: tipoCusto.casasDecimais
                                      }, null, 8, ["valor", "onUpdate:valor", "quantidadeCasasDecimais"])
                                    ]),
                                    _: 2
                                  }, 1032, ["label"])
                                ]),
                                _createElementVNode("div", _hoisted_7, [
                                  _createVNode(_component_a_form_item, {
                                    label: "Última atualização",
                                    class: "ss-margin-campos"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_selecionar_data, {
                                        dataSelecionada: _ctx.computedCustos[_ctx.obtemIndexTipoCusto(tipoCusto.codigo)].dataAtualizacao,
                                        "onUpdate:dataSelecionada": ($event: any) => ((_ctx.computedCustos[_ctx.obtemIndexTipoCusto(tipoCusto.codigo)].dataAtualizacao) = $event),
                                        disabled: true,
                                        placeholder: ""
                                      }, null, 8, ["dataSelecionada", "onUpdate:dataSelecionada"])
                                    ]),
                                    _: 2
                                  }, 1024)
                                ])
                              ], 2)
                            ]))
                          }), 128))
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]))
  ]))
}